






















import { Component, Vue } from 'vue-property-decorator'
import {
  callAppFunc_getSomeParams,
  callAppFunc_dbSet,
  callAppFunc_openGameForGame,
  callAppFunc_dbGetForGame,
  callAppFunc_installGameForGame,
  appFunc_getDownProgress
} from '@/utils/bridge'
import { getPlatform } from '@/utils/verify'
import { CommonModule } from '@/store/modules/common'
import md5 from 'js-md5'
@Component({
  name: 'Spread'
})
export default class Spread extends Vue {
  // 是否显示引导图
  isModel = false

  // id区分不同的渠道包
  id: any = ''

  landingpageBg =
    'https://source.allosoft.top/web/h5-ad-spread/landing-page-bg.png'

  created() {
    this.id = this.$route.query.id
    console.log('进入落地页时上报', this.id)
    const { aplus_queue }: any = window
    // 进入落地页时上报
    if (this.id == 1) {
      console.log('落地页浏览（kz开机弹窗）')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_kjtc_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 2) {
      console.log('kz-前贴')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_qt_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 3) {
      console.log('kz-暂停')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_zt_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 4) {
      console.log('kz-开机')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_kjgg_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 6) {
      console.log('kz-banner')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_banner_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 7) {
      console.log('kx-开机弹窗')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_kj_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 8) {
      console.log('kx-前贴')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_qt_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 9) {
      console.log('kx-暂停')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_zt_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 12) {
      console.log('kz-积分中心')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_jifenqiang_landing_page_view', 'CLK', {}]
      })
    } else if (this.id == 13) {
      console.log('kx-个人中心icon')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_pcicon_landing_page_view', 'CLK', {}]
      })
    }
  }

  async mounted() {
    console.log('图片全部加载完成后上报')
    // 图片全部加载完成后上报
    const { aplus_queue }: any = window
    if (this.id == 1) {
      console.log('有效浏览（kz开机弹窗）')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_kjtc_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 2) {
      console.log('kz-前贴')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_qt_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 3) {
      console.log('有效浏览（kz暂停）')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_zt_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 4) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_kjgg_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 6) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_banner_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 8) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_qt_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 7) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_kj_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 9) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_zt_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 12) {
      console.log('kz-积分中心有效浏览')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_jifenqiang_Browse_effectively', 'CLK', {}]
      })
    } else if (this.id == 13) {
      console.log('kx-个人中心icon有效浏览')
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_pcicon_Browse_effectively', 'CLK', {}]
      })
    }
  }

  getValue(apkUrl: string) {
    return {
      id: 15,
      name: 'Allo', // App 名称
      desc: 'جىبو قىلىپ دوستلىشىدىغان ئۇيغۇرچە ئەپ دېتال', // App描述
      image: 'http://source.allosoft.top/web/h5-ad-spread/ic_launcher.png', // 下载 app 显示的图片
      status: 0,
      downloadTime: 0,
      progress: 0,
      downloadPath: '',
      packageName: 'com.allo.contacts', // App 包名
      apkUrl: apkUrl, // App 包链接
      size: 0,
      downloadId: '',
      md5: md5('allo'),
      versionName: '2.3'
    }
  }

  // 下载完成回调
  getDownProgress(data: any) {
    const { percent } = JSON.parse(data)
    const { aplus_queue }: any = window
    if (this.id == 1 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_kjtc_completed', 'CLK', {}]
      })
    } else if (this.id == 2 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_qt_completed', 'CLK', {}]
      })
    } else if (this.id == 3 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_zt_completed', 'CLK', {}]
      })
    } else if (this.id == 4 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_kjgg_completed', 'CLK', {}]
      })
    } else if (this.id == 6 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_banner_completed', 'CLK', {}]
      })
    } else if (this.id == 8 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_qt_completed', 'CLK', {}]
      })
    } else if (this.id == 7 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_kj_completed', 'CLK', {}]
      })
    } else if (this.id == 9 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_zt_completed', 'CLK', {}]
      })
    } else if (this.id == 12 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kz_jifenqiang_completed', 'CLK', {}]
      })
    } else if (this.id == 13 && percent == 100) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['kx_pcicon_completed', 'CLK', {}]
      })
    }
  }

  // 下载
  async handelDownload() {
    const pla = getPlatform()
    if (pla === 'koznak') {
      console.log('进入koznak内部页面的时候打印一下')
      // 获取设备信息
      const res = await callAppFunc_getSomeParams()
      console.log('获取设备信息', res)
      CommonModule.setAppVersion(res.version)
      const isinstall = await callAppFunc_dbGetForGame({
        name: 'Allo',
        packageName: 'com.allo.contacts',
        versionName: '2.2.4',
        md5: md5('allo')
      })
      console.log('调起原生数据库', isinstall)
      if (this.id == 1) {
        console.log('落地页下载（kz开机弹窗）')
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kz_kjtc_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('开始安装（kz开机弹窗）')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kz_kjtc_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_koznaktanchuang)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kz_kjtc_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 2) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kz_qt_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('开始安装（kz前贴）')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kz_qt_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_koznakshipinqt)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kz_qt_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 3) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kz_zt_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('开始安装（kz暂停）')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kz_zt_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_koznakzanting)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kz_zt_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 4) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kz_kjgg_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('开始安装（kz开机）')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kz_kjgg_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_koznakkaiji)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kz_kjgg_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 5) {
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_koznakxiangqing)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
        }
      } else if (this.id == 6) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kz_banner_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('开始安装（kz-banner）')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kz_banner_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_koznakbanner)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kz_banner_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 12) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kz_jifenqiang_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kz_jifenqiang_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_jifenqiang)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kz_jifenqiang_open_allo', 'CLK', {}]
          })
        }
      }
    } else if (pla === 'kixmix') {
      const res = await callAppFunc_getSomeParams()
      CommonModule.setAppVersion(res.version)
      console.log('获取设备信息', res)
      const isinstall = await callAppFunc_dbGetForGame({
        name: 'Allo',
        packageName: 'com.allo.contacts',
        versionName: '2.2.4',
        md5: md5('allo')
      })
      console.log('调起原生数据库', isinstall)
      if (this.id == 7) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kx_kj_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('kx-开机弹窗开始安装')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kx_kj_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_kixmixtanchuang)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kx_kj_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 8) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kx_qt_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('kx-前贴开始安装')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kx_qt_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_kixmixqiantie)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kx_qt_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 9) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kx_zt_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            console.log('kx-暂停开始安装')
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kx_zt_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_kixmixzanting)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kx_zt_open_allo', 'CLK', {}]
          })
        }
      } else if (this.id == 10) {
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_kixmixsybanner)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
        }
      } else if (this.id == 11) {
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_kixmixkaiji)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
        }
      } else if (this.id == 13) {
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['kx_pcicon_Landing_page_download', 'CLK', {}]
        })
        // apk没有安装
        if (!isinstall.isApkInstal) {
          // apk已经下载
          if (isinstall.isFileDown) {
            // 安装apk
            callAppFunc_installGameForGame({
              name: 'Allo',
              packageName: 'com.allo.contacts',
              versionName: '2.2.4',
              md5: md5('allo')
            })
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['kx_pcicon_Download_completed', 'CLK', {}]
            })
          } else {
            const data = {
              key: 'com.allo.contacts',
              value: this.getValue(process.env.VUE_APP_kixmixPCicon)
            }
            console.log('下载信息', data)
            callAppFunc_dbSet(data)
            appFunc_getDownProgress(this.getDownProgress)
          }
        } else {
          // 已经安装打开app
          callAppFunc_openGameForGame({
            name: 'Allo',
            packageName: 'com.allo.contacts',
            versionName: '2.2.4',
            md5: md5('allo')
          })
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['kx_pcicon_open_allo', 'CLK', {}]
          })
        }
      }
    } else if (pla === 'weixin' || pla === 'qq') {
      this.isModel = true
    } else {
      console.log('进入浏览器器页面的时候打印一下')
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=1'
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href = process.env.VUE_APP_ALLO
      }, 500)
      this.isModel = false
    }
  }
}
